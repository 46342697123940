<template>
  <div id="form">
    <!-- Form -->
    <el-form
      :ref="formName"
      :model="queryAdd"
      :rules="queryRule"
      label-width="100px"
      label-position="right"
    >
      <!-- 用户帐号 -->
      <el-row :gutter="20">
        <el-col :span="11">
          <el-form-item
            label="用户帐号"
            prop="username"
          >
            <el-input v-model="queryAdd.username" />
          </el-form-item>
        </el-col>
        <el-col
          :span="11"
          :offset="2"
        >
          <!-- 会员类别 -->
          <el-form-item
            label="会员类别"
            prop="category"
          >
            <el-select
              v-model="queryAdd.category"
              style="width:100%;"
            >
              <el-option
                v-for="(option, idx) in categoryOption"
                :key="'option-' + idx"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="11">
          <!-- 用户昵称 -->
          <el-form-item
            label="昵称"
            prop="nickname"
          >
            <el-input v-model="queryAdd.nickname" />
          </el-form-item>
        </el-col>
        <el-col
          :span="11"
          :offset="2"
        >
          <!-- 等级 -->
          <el-form-item
            label="等级"
            prop="level"
          >
            <el-input-number
              :disabled="true"
              v-model="queryAdd.level"
              :min="1"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="11">
          <!-- 邮箱地址 -->
          <el-form-item
            label="邮箱"
            prop="email"
          >
            <el-input v-model="queryAdd.email" />
          </el-form-item>
        </el-col>
        <el-col
          :span="11"
          :offset="2"
        >
          <!-- 手机 -->
          <el-form-item
            label="手机"
            prop="mobile"
          >
            <el-input v-model="queryAdd.mobile" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="11">
          <!-- QQ -->
          <el-form-item
            label="QQ"
            prop="qq"
          >
            <el-input v-model="queryAdd.qq" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="11">
          <!-- 设置密码 -->
          <el-form-item
            label="设置密码"
            prop="password"
          >
            <el-input
              v-model="queryAdd.password"
              type="password"
            />
          </el-form-item>
        </el-col>
        <el-col
          :span="11"
          :offset="2"
        >
          <!-- 确认密码 -->
          <el-form-item
            label="确认密码"
            prop="confirm_password"
          >
            <el-input
              v-model="queryAdd.confirm_password"
              type="password"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- Footer -->
    <div
      slot="footer"
      class="dialog-footer"
    >
      <!-- 取消 -->
      <el-button @click="handleCancel">
        取消
      </el-button>
      <!-- 确认 -->
      <el-button
        type="primary"
        @click="handleSubmit"
      >
        确认
      </el-button>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { categoryOption } from '@/constants/member'
import { postAddUser } from '@/api/admin/member'
import { member as msg } from '@/constants/msgAdmin'

export default {
  data () {
    return {
      formName: 'addForm',
      queryAdd: {
        username: '',
        category: '1',
        nickname: '',
        level: '1',
        email: '',
        countrycode: '',
        mobile: '',
        qq: '',
        password: '',
        confirm_password: '',
        state: 'active'
      },
      queryRule: {
        username: [
          {
            required: true,
            message: '请输入用户名称, 8字以上大小写英文数字',
            trigger: 'blur'
          },
          {
            min: 8,
            message: '请输入用户名称, 8字以上大小写英文数字',
            trigger: 'blur'
          }
        ],
        // nickname: [
        //   {
        //     required: true,
        //     message: '请输入用户昵称',
        //     tribber: 'blur'
        //   }
        // ],
        email: [
          {
            required: true,
            message: '请输入邮箱地址',
            tribber: 'blur'
          },
          {
            validator: this.validateEmail,
            trigger: 'blur'
          }
        ],
        // category: [
        //   {
        //     required: true,
        //     message: '请选择用户角色',
        //     trigger: 'change'
        //   }
        // ],
        password: [
          {
            required: true,
            message: msg.validatePass,
            trigger: 'blur'
          },
          {
            validator: this.validatePass,
            trigger: 'blur'
          }
        ],
        confirm_password: [
          {
            required: true,
            message: msg.confirmPass,
            trigger: 'blur'
          },
          {
            validator: this.validateComfirmPass,
            trigger: 'blur'
          }
        ]
      },
      categoryOption: categoryOption
    }
  },
  methods: {
    validateEmail (rule, value, callback) {
      if (!this.regexEmail(value)) {
        callback(new Error('请输入正确的邮箱地址'))
      } else {
        callback()
      }
    },
    regexEmail (str) {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regex.test(String(str))
    },
    validatePass (rule, value, callback) {
      if (!this.regexPass(value)) {
        callback(new Error(msg.validatePass))
      } else {
        // if (value !== '') {
        //   this.$refs[this.formName].validateField('confirm_password')
        // }
        callback()
      }
    },
    validateComfirmPass (rule, value, callback) {
      if (value !== this.queryAdd.password) {
        callback(new Error(msg.validateConfirmPass))
      } else {
        callback()
      }
    },
    regexPass (password) {
      const regex = /^[a-zA-Z0-9._@]{6,}$/
      return regex.test(String(password))
    },
    handleSubmit () {
      this.$refs[this.formName].validate(valid => {
        if (valid) { this.addUser() }
      })
    },
    handleCancel () {
      // this.$refs[this.formName].resetFields()
      // this.dialogVisible = false
      this.$router.go(-1)
    },
    addUser () {
      postAddUser(this.queryAdd)
        .then(res => {
          switch (res.status) {
            case resStatus.OK:
              this.$emit('handleRefresh')
              this.handleCancel()
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#form {
  max-width: calc((100vw - 200px) * 0.8);
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 20;
    }
  }
  .el-input-number {
    width: 100%;
  }
}
</style>
