import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'

// 新增會員
export function postAddUser (data) {
  return request({
    url: '/api/console/manage_users',
    method: 'post',
    data: getFormData(data)
  })
}

// 修改會員
export function postEditUser (data, id) {
  return request({
    url: `/api/console/manage_users/${id}`,
    method: 'post',
    data: getFormData(data)
  })
}

// 修改密碼
export function postEditPass (data) {
  return request({
    url: '/api/console/manage_users/modifypass',
    method: 'post',
    data: getFormData(data)
  })
}

// 取得會員列表
export function getUserList (params) {
  return request({
    url: '/api/console/manage_users',
    method: 'get',
    params: params
  })
}

// 帳號 激活/禁用 (一個或多個)
export function getUserStatus (id, statusType) {
  // const status = statusType ? 'active' : 'blocked'
  return request({
    url: `/api/console/manage_users/${id}`,
    method: 'get',
    params: {
      status: statusType
    }
  })
}

// 取得單一會員
export function getUser (id) {
  return request({
    url: `/api/console/manage_users/${id}`,
    method: 'get'
  })
}
